export default {

  getQuarter(skip) {
    const date = new Date()
    let year = date.getFullYear()
    let quarter = Math.ceil((date.getMonth() + 1) / 3)

    for (let i = 0; i < skip; i += 1) {
      quarter -= 1
      if (quarter === 0) {
        quarter = 4
        year -= 1
      }
    }

    return `Q${quarter}/${year}`
  },

  getQuarters(number, skip = 0) {
    let times = []
    const date = new Date()
    let year = date.getFullYear()
    let quarter = Math.ceil((date.getMonth() + 1) / 3)
    for (let i = 0; i < number + skip; i += 1) {
      if (quarter === 0) {
        quarter = 4
      }
      if (i > skip - 1) { // skip current quarter
        times = [`${quarter}${year}`, ...times]
      }
      quarter -= 1
      if (quarter === 0) {
        year -= 1
      }
    }

    return times
  },

  getYears(number, skip = 0) {
    let times = []
    const date = new Date()
    let year = date.getFullYear() - skip
    for (let i = 0; i < number; i += 1) {
      times = [year.toString(), ...times]
      year -= 1
    }

    return times
  },

  getTerms(number, lastTerm) {
    let times = []
    let year
    let quarter
    if (lastTerm) {
      year = parseInt(lastTerm.slice(-4), 10)
      quarter = parseInt(lastTerm.slice(0, 1), 10)
    } else {
      const date = new Date()
      year = date.getFullYear()
      quarter = Math.ceil((date.getMonth() + 1) / 3)
    }

    for (let i = 0; i < number; i += 1) {
      if (quarter === 0) {
        quarter = 4
      }

      times = [`${quarter}${year}`, ...times]

      quarter -= 1
      if (quarter === 0) {
        year -= 1
      }
    }

    return times
  },
}
