<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text
            class="d-flex"
          >
            <div
              class="mr-5"
              style="width:350px"
            >
              <SelectStock
                @symbol-changed="setSymbol"
              />
            </div>
            <div style="width:80px">
              <v-select
                v-model="termNum"
                :items="[4, 6, 8, 10, 12, 14, 16]"
                dense
                outlined
                hide-details
                label="Số kỳ"
              />
            </div>
            <!-- <div style="width:100px">
              <SelectTimeInterval
                @interval-changed="setInterval"
              />
            </div> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="chart in visibleCharts"
        :key="chart.id"
        cols="12"
        :md="md"
      >
        <DataProvider
          :symbol="symbol"
          :end="times.length ? times[times.length - 1] : ''"
          :range="termNum"
          :indicators="chart.indicators"
          :unit="chart.units ? null : chart.unit"
        >
          <template v-slot="{ data, loading }">
            <Chart
              :title="chart.title"
              :loading="loading"
              :data="data"
              :indicators="chart.indicators"
              :times="times"
              :show-data-label="chartOptions.dataLabels"
              :show-legend="chartOptions.legend"
            />
          </template>
        </DataProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import TimeUtils from '@/common/utils/time'
import { INTERVAL } from '@/common/constants'

export default {
  components: {
    SelectStock: () => import('./SelectStock.vue'),
    // SelectTimeInterval: () => import('./SelectTimeInterval.vue'),
    DataProvider: () => import('./DataProvider'),
    Chart: () => import('./chartjs/Chart.vue'),
  },

  props: {
    double: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      symbol: '',
      businessType: 1,
      termNum: 8,
      interval: INTERVAL.QUARTERLY,
    }
  },

  computed: {
    ...mapGetters('chart', ['charts']),
    ...mapGetters('user', ['chartOptions']),
    ...mapGetters('company', ['getBySymbol']),
    md() {
      return this.double ? 6 : 12
    },
    visibleCharts() {
      return this.charts.filter(chart => chart.is_active && (chart.business_type === this.businessType))
    },
    times() {
      const company = this.getBySymbol(this.symbol)
      if (!company) return []

      return TimeUtils.getTerms(this.termNum, company.last_updated_term, this.interval)
    },
  },

  created() {
    if (!this.charts.length) {
      this.FETCH_CHARTS()
    }
  },

  methods: {
    ...mapActions('chart', ['FETCH_CHARTS']),
    setSymbol(value) {
      this.symbol = value
      const company = this.getBySymbol(this.symbol)
      if (company) {
        this.businessType = company.business_type
      }
    },

    setInterval(value) {
      this.interval = value
    },
  },
}

</script>
